.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.langSelect {
  display: inline-block;
  padding: 15px;
  background: black;
  transition: 0.3s;
  margin: 15px;
  color: white;
  border: none;
  cursor: pointer;
}
.save {
  display: inline-block;
  padding: 15px;
  background: black;
  transition: 0.3s;
  margin-right: 15px;
  color: white;
  border: none;
  cursor: pointer;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.input-field {
  padding: 10px;
  margin-bottom: 20px;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #45a049;
}

.header {
  position: sticky;
  top: 0;
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.langSelect:hover {
  background: gray;
}
#langbody{
  padding:15px;
}
a {
  color: white;
  text-decoration: none;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#langbody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
}

.save {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.save:hover {
  background-color: #45a049;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 10px;
}
tr:hover {
  transition: 0.3s;
  background: lightblue;
}
textarea {
  width: 95%;
  height: 100px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

